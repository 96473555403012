import Link from "next/link";
import React from "react";
import dynamic from "next/dynamic";
const FacebookIcon = dynamic(
  async () => await import("@components/icons/FacebookIcon")
);
const InstagramIcon = dynamic(
  async () => await import("@components/icons/InstagramIcon")
);
const TikTokIcon = dynamic(
  async () => await import("@components/icons/TikTokIcon")
);
const TwitterIcon = dynamic(
  async () => await import("@components/icons/TwitterIcon")
);

function SocialIcons(): JSX.Element {
  const currentYear = new Date().getFullYear();
  const socialIcons = [
    {
      id: "facebookLink",
      icon: <FacebookIcon />,
      iconLink: "https://www.facebook.com/costaricanvacations",
    },
    {
      id: "twitterLink",
      icon: <TwitterIcon />,
      iconLink: "https://twitter.com/CRVTravel",
    },
    {
      id: "instagramLink",
      icon: <InstagramIcon />,
      iconLink: "https://www.instagram.com/costaricanvacations/",
    },
    {
      id: "tikTokLink",
      icon: <TikTokIcon />,
      iconLink: "https://www.tiktok.com/@costaricanvacations",
    },
  ];
  return (
    <div className="flex w-full flex-col items-center desktop:m-auto desktop:justify-between">
      <div className="flex gap-4">
        {socialIcons.map((link) => (
          <Link
            href={link.iconLink}
            passHref
            target={"_blank"}
            key={link.id}
            className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-xl bg-white hover:bg-blue-20"
            rel="noreferrer"
          >
            {link.icon}
          </Link>
        ))}
      </div>
      <p className="mt-5 mb-10 flex text-gray">
        &copy; Copyright, {currentYear} all rights reserved.
      </p>
    </div>
  );
}

export default SocialIcons;
